import { mCoderApi, customTransformErrorResponse } from './base';

const mCoderMeApi = mCoderApi.injectEndpoints({
  tagTypes: ['Auth'],
  endpoints: (builder) => ({
    me: builder.query({
      query: () => '/me',
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Auth'],
    }),
    authorized: builder.mutation({
      query: () => ({
        url: `/authorized`,
        method: 'POST',
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: ['Auth'],
    }),
    logout: builder.mutation({
      query: () => ({
        url: `/logout`,
        method: 'POST',
      }),
      transformErrorResponse: customTransformErrorResponse,
    }),
    impersonate: builder.mutation({
      query: ({ role }) => ({
        url: `/impersonate?role=${role}`,
        method: 'POST',
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: ['Auth'],
    }),
    getMyQueues: builder.query({
      query: () => '/me/queues',
      transformResponse: (response, meta, arg) => response.queues2,
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Queues2'],
    }),
    getMyClients: builder.query({
      query: () => '/me/clients',
      transformResponse: (response, meta, arg) => response.clients && response.clients.length > 0 ? response.clients[0] : null,
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Clients'],
    }),
    assignedToMyself: builder.mutation({
      query: ({ caseIDList }) => ({
        url: `/me/cases`,
        method: 'POST',
        body: caseIDList,
      }),
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        { type: 'Cases' },
        ...arg.caseIDList.map(caseID => ({ type: 'Assignees', id: arg.caseID })),
        ...arg.caseIDList.map(caseID => ({ type: 'Events', id: arg.caseID })),
        ...arg.caseIDList.map(caseID => ({ type: 'CaseDetail', id: arg.caseID })),
      ],
    }),
    unassignedFromMyself: builder.mutation({
      query: ({ caseIDList }) => ({
        url: `/me/cases`,
        method: 'DELETE',
        body: caseIDList,
      }),
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        { type: 'Cases' },
        ...arg.caseIDList.map(caseID => ({ type: 'Assignees', id: arg.caseID })),
        ...arg.caseIDList.map(caseID => ({ type: 'Events', id: arg.caseID })),
        ...arg.caseIDList.map(caseID => ({ type: 'CaseDetail', id: arg.caseID })),
      ],
    }),
  }),
  overrideExisting: true,
});

export const {
  useMeQuery,
  useLazyMeQuery,
  useGetMyQueuesQuery,
  useGetMyClientsQuery,
  useAuthorizedMutation,
  useLogoutMutation,
  useImpersonateMutation,
  useAssignedToMyselfMutation,
  useUnassignedFromMyselfMutation
} = mCoderMeApi;
