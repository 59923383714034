import { mCoderApi, customTransformErrorResponse } from './base';

const mCoderClientsApi = mCoderApi.injectEndpoints({
  tagTypes: ['Clients', 'Client_Users', 'Client_Cases_Count'],
  endpoints: (builder) => ({
    getClients: builder.query({
      query: () => `/clients`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response.clients,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Clients'],
    }),
    getClient: builder.query({
      query: (clientID) => `/clients/${clientID}`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) =>
        response.clients && response.clients.length > 0 ? response.clients[0] : null,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Clients'],
    }),
    createClient: builder.mutation({
      query: ({ name, description, clientGroupID: client_group_id }) => ({
        url: `/clients`,
        method: 'POST',
        body: {
          name,
          description,
          client_group_id,
        },
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => ['Clients'],
    }),
    deleteClient: builder.mutation({
      query: ({ clientID }) => ({
        url: `/clients/${clientID}`,
        method: 'DELETE',
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => ['Clients'],
    }),
    addUserToClient: builder.mutation({
      query: ({ clientID, userID }) => ({
        url: `/clients/${clientID}/users/${userID}`,
        method: 'POST',
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        { type: 'Users', id: arg.userID },
        { type: 'Clients' },
        { type: 'Client_Users' },
      ],
    }),
    removeUserFromClient: builder.mutation({
      query: ({ clientID, userID }) => ({
        url: `/clients/${clientID}/users/${userID}`,
        method: 'DELETE',
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        { type: 'Users', id: arg.userID },
        { type: 'Clients' },
        { type: 'Client_Users' },
      ],
    }),
    getCaseCountsByClientID: builder.query({
      query: ({ clientID }) => `/clients/${clientID}/case_counts`,
      transformResponse: (response, meta, arg) => response,
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Client_Cases_Count'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetClientsQuery,
  useGetClientQuery,
  useLazyGetClientQuery,
  useCreateClientMutation,
  useDeleteClientMutation,
  useAddUserToClientMutation,
  useRemoveUserFromClientMutation,
  useGetCaseCountsByClientIDQuery,
} = mCoderClientsApi;
