import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import { useAddProviderToClientHandler, useRemoveProviderFromClientHandler } from '../hooks';
import { useClients } from '../../clients/hooks';

SetClientDialog.propTypes = {
  user: PropTypes.object,
  defaultClient: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  sx: PropTypes.object,
};

export function SetClientDialog({ user, defaultClient, open, onClose: handleClose, sx }) {
  const [selectedClientID, setSelectedClientID] = useState(defaultClient?.id ?? '');
  const handleChange = (event) => {
    setSelectedClientID(event.target.value);
  };
  const [addProviderToClient, addProviderToClientResult] = useAddProviderToClientHandler(user, handleClose);
  const [removeProviderFromClient, removeProviderFromClientResult] = useRemoveProviderFromClientHandler(
    user,
    defaultClient,
    handleClose
  );
  const [clients] = useClients();
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle>Choose Client</DialogTitle>
      <DialogContent>
        <FormControl>
          <RadioGroup row name="client-radio-buttons-group" value={selectedClientID} onChange={handleChange}>
            {clients.map((client) => (
              <FormControlLabel key={client.id} value={client.id} control={<Radio />} label={client.name} />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="inherit" size="small">
          Cancel
        </Button>
        <LoadingButton
          disabled={selectedClientID === null || selectedClientID === defaultClient?.id}
          onClick={() => addProviderToClient(selectedClientID)}
          variant="contained"
          color="primary"
          size="small"
          loading={addProviderToClientResult.isLoading}
        >
          Set user to Client
        </LoadingButton>
        {defaultClient && (
          <LoadingButton
            onClick={removeProviderFromClient}
            variant="contained"
            color="error"
            size="small"
            loading={removeProviderFromClientResult.isLoading}
          >
            Remove user from Client [{defaultClient.name}]
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
