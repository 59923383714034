import { useCallback } from 'react';
// api
import { useAssignCasesToUserMutation } from '../../apis/mCoderApi';
// hooks
import { useErrorNotice, useSuccessNotice, useWarningNotice } from '../../../hooks';

export function useAssignCasesToUserHandler(caseIDList, handleSuccess = () => {}) {
  const noticeError = useErrorNotice();
  const noticeSuccess = useSuccessNotice();
  const noticeWarning = useWarningNotice();
  const [assignCasesToUser, assignCasesToUserResult] = useAssignCasesToUserMutation();
  return [
    useCallback(
      (user) => {
        if (user) {
          assignCasesToUser({ userID: user.id, caseIDList })
            .unwrap()
            .then(() => {
              noticeSuccess(`${user.name} is assigned to ${caseIDList?.length} cases.`);
              handleSuccess();
            })
            .catch((error) => {
              noticeError(error);
            });
        } else {
          noticeWarning('No user is selected for assignment.');
          handleSuccess();
        }
      },
      [assignCasesToUser, noticeError, noticeSuccess, noticeWarning, caseIDList, handleSuccess]
    ),
    assignCasesToUserResult,
  ];
}
