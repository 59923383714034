import { mCoderApi, customTransformErrorResponse } from './base';

const mCoderCasesApi = mCoderApi.injectEndpoints({
  tagTypes: ['Cases', 'CaseDetail', 'Assignees'],
  endpoints: (builder) => ({
    getCaseStatusCounts: builder.query({
      query: (urlSearchParams) => (urlSearchParams ? `/case_status_counts?${urlSearchParams}` : '/case_status_counts'),
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Cases'],
    }),
    getCases: builder.query({
      query: (urlSearchParams) => (urlSearchParams ? `/cases?${urlSearchParams}` : '/cases'),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response.cases,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Cases'],
    }),
    getCase: builder.query({
      query: ({ caseID }) => `/cases/${caseID}`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => (response.cases.data.length > 0 ? response.cases.data[0] : null),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: (result, error, arg) => [{ type: 'CaseDetail', id: arg.caseID }],
    }),
    getCaseWebhookLogs: builder.query({
      query: ({ caseID, skip = 0, limit = 10 }) => `/cases/${caseID}/webhook_logs?skip=${skip}&limit=${limit}`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response.webhook_logs,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
    }),
    addTagToCase: builder.mutation({
      query: ({ caseID, tagGroups }) => ({
        url: `/cases/${caseID}/tag_groups`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: tagGroups,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'CaseDetail', id: arg.caseID },
        { type: 'Events', id: arg.caseID },
      ],
    }),
    removeTagFromCase: builder.mutation({
      query: ({ caseID, tagGroupName, tagName }) => ({
        url: tagName
          ? `/cases/${caseID}/tag_groups/${tagGroupName}/tags/${tagName}`
          : `/cases/${caseID}/tag_groups/${tagGroupName}`, // if no tagName, then remove the whole groups
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'CaseDetail', id: arg.caseID },
        { type: 'Events', id: arg.caseID },
      ],
    }),
    requestCoding: builder.mutation({
      query: ({ caseID }) => ({ url: `/cases/${caseID}/actions/request_coding`, method: 'POST' }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        { type: 'CaseDetail', id: arg.caseID },
        { type: 'Events', id: arg.caseID },
        { type: 'Cases' },
        { type: 'Client_Cases_Count' },
      ],
    }),
    hold: builder.mutation({
      query: ({ caseID }) => ({ url: `/cases/${caseID}/actions/hold`, method: 'POST' }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        { type: 'Events', id: arg.caseID },
        { type: 'CaseDetail', id: arg.caseID },
        { type: 'Cases' },
        { type: 'Client_Cases_Count' },
      ],
    }),
    save: builder.mutation({
      query: ({ caseID, humanResult }) => ({
        url: `/cases/${caseID}/actions/save`,
        method: 'POST',
        body: humanResult,
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        { type: 'CaseDetail', id: arg.caseID },
        { type: 'Events', id: arg.caseID },
      ],
    }),
    commitCase: builder.mutation({
      query: ({ caseID }) => ({ url: `/cases/${caseID}/actions/commit_case`, method: 'POST' }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        { type: 'CaseDetail', id: arg.caseID },
        { type: 'Events', id: arg.caseID },
        { type: 'Cases' },
        { type: 'Client_Cases_Count' },
      ],
    }),
    reopenCase: builder.mutation({
      query: ({ caseID, tagGroups }) => ({
        url: `/cases/${caseID}/actions/reopen`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: tagGroups,
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        { type: 'CaseDetail', id: arg.caseID },
        { type: 'Events', id: arg.caseID },
        { type: 'Cases' },
        { type: 'Client_Cases_Count' },
      ],
    }),
    deleteCase: builder.mutation({
      query: ({ caseID }) => ({ url: `/cases/${caseID}/actions/delete_case`, method: 'POST' }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        { type: 'CaseDetail', id: arg.caseID },
        { type: 'Events', id: arg.caseID },
      ],
    }),
    resetDeletedCase: builder.mutation({
      query: ({ caseID }) => ({ url: `/cases/${caseID}/actions/reset_deleted_case`, method: 'POST' }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        { type: 'CaseDetail', id: arg.caseID },
        { type: 'Events', id: arg.caseID },
      ],
    }),
    duplicateCase: builder.mutation({
      query: ({ caseID, duplicatedOf }) => ({
        url: `/cases/${caseID}/actions/duplicate_case`,
        method: 'POST',
        body: duplicatedOf,
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        { type: 'CaseDetail', id: arg.caseID },
        { type: 'Events', id: arg.caseID },
      ],
    }),
    resetDuplicatedCase: builder.mutation({
      query: ({ caseID }) => ({
        url: `/cases/${caseID}/actions/reset_duplicated_case`,
        method: 'POST',
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        { type: 'CaseDetail', id: arg.caseID },
        { type: 'Events', id: arg.caseID },
      ],
    }),
    requestProviderPreReview: builder.mutation({
      query: ({ caseID }) => ({ url: `/cases/${caseID}/actions/request_provider_prereview`, method: 'POST' }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        { type: 'CaseDetail', id: arg.caseID },
        { type: 'Events', id: arg.caseID },
        { type: 'Cases' },
        { type: 'Client_Cases_Count' },
      ],
    }),
    requestProviderReview: builder.mutation({
      query: ({ caseID, userID }) => ({
        url: `/cases/${caseID}/actions/request_provider_review`,
        method: 'POST',
        body: userID,
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        { type: 'CaseDetail', id: arg.caseID },
        { type: 'Events', id: arg.caseID },
        { type: 'Cases' },
        { type: 'Client_Cases_Count' },
      ],
    }),
    submitProviderFeedback: builder.mutation({
      query: ({ caseID, feedback }) => ({
        url: `/cases/${caseID}/actions/submit_provider_feedback`,
        method: 'POST',
        body: feedback,
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        { type: 'CaseDetail', id: arg.caseID },
        { type: 'Events', id: arg.caseID },
        { type: 'Cases' },
        { type: 'Client_Cases_Count' },
      ],
    }),
    unassignedCases: builder.mutation({
      query: ({ caseIDList }) => ({
        url: `/cases/-/users`,
        method: 'DELETE',
        body: caseIDList,
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        { type: 'Cases' },
        ...arg.caseIDList.map((caseID) => ({ type: 'Assignees', id: arg.caseID })),
        ...arg.caseIDList.map((caseID) => ({ type: 'Events', id: arg.caseID })),
        ...arg.caseIDList.map((caseID) => ({ type: 'CaseDetail', id: arg.caseID })),
      ],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCaseStatusCountsQuery,
  useLazyGetCasesQuery,
  useGetCasesQuery,
  useGetCaseQuery,
  useLazyGetCaseQuery,
  useGetCaseWebhookLogsQuery,
  useAddTagToCaseMutation,
  useRemoveTagFromCaseMutation,
  useRequestCodingMutation,
  useHoldMutation,
  useSaveMutation,
  useCommitCaseMutation,
  useReopenCaseMutation,
  useRequestProviderPreReviewMutation,
  useRequestProviderReviewMutation,
  useSubmitProviderFeedbackMutation,
  useUnassignedCasesMutation,
  useDeleteCaseMutation,
  useResetDeletedCaseMutation,
  useDuplicateCaseMutation,
  useResetDuplicatedCaseMutation,
} = mCoderCasesApi;
