import { Icon } from '@iconify/react';

// @mui
import { Button } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/useAuthContext';

export default function AuthWithMS() {
    const { login } = useAuthContext();

    return (
        <Button
            fullWidth
            variant="contained"
            startIcon={<Icon icon="mdi:microsoft" />}
            onClick={() => login()}
        >
            Sign in with Microsoft Account
        </Button>
    );
}
