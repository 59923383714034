import { useEffect } from 'react';
// api
import { useGetCaseCountsByClientIDQuery } from '../../apis/mCoderApi';
// hooks
import { useErrorNotice } from '../../../hooks';

export function useGetCaseCountsByClientID(clientID) {
  const noticeError = useErrorNotice();
  const getCaseCountsByClientID = useGetCaseCountsByClientIDQuery({ clientID });
  useEffect(() => {
    if (getCaseCountsByClientID.isError) {
      noticeError(getCaseCountsByClientID.error);
    }
  }, [getCaseCountsByClientID, noticeError]);
  return getCaseCountsByClientID;
}

export function useCaseCountsByClientID(clientID) {
  const getCaseCountsByClientID = useGetCaseCountsByClientID(clientID);
  return [getCaseCountsByClientID.data ?? [], getCaseCountsByClientID];
}
