import { mCoderApi, customTransformErrorResponse } from './base';

const mCoderUsersApi = mCoderApi.injectEndpoints({
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: ({ include_invited_user }) => ({
        url: `/users`,
        params: { include_invited_user: include_invited_user ?? false },
        timeout: 10000,
      }),
      transformResponse: (response, meta, arg) => response.users,
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Users'],
    }),
    getUser: builder.query({
      query: ({ userID }) => ({
        url: `/users/${userID}`,
      }),
      transformResponse: (response, meta, arg) =>
        response.users && response.users.length > 0 ? response.users[0] : null,
      transformErrorResponse: customTransformErrorResponse,
      providesTags: (result, error, arg) => [{ type: 'Users', id: arg.userID }],
    }),
    inviteUser: builder.mutation({
      query: ({ email }) => ({
        url: `/users?email=${email}`,
        method: 'POST',
      }),
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: ['Users'],
    }),
    addUser: builder.mutation({
      query: ({ userID }) => ({
        url: `/users/${userID}`,
        method: 'POST',
      }),
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: ['Users'],
    }),
    forceLogout: builder.mutation({
      query: ({ userID }) => ({
        url: `/users/${userID}/logout`,
        method: 'POST',
      }),
      transformErrorResponse: customTransformErrorResponse,
    }),
    addRoleToUser: builder.mutation({
      query: ({ userID, role }) => ({
        url: `/users/${userID}/roles/${role}`,
        method: 'POST',
      }),
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [{ type: 'Users', id: arg.userID }, { type: 'Users' }, { type: 'Auth' }],
    }),
    deleteRoleFromUser: builder.mutation({
      query: ({ userID, role }) => ({
        url: `/users/${userID}/roles/${role}`,
        method: 'DELETE',
      }),
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [{ type: 'Users', id: arg.userID }, { type: 'Users' }, { type: 'Auth' }],
    }),
    getClientByUserID: builder.query({
      query: ({ userID }) => ({
        url: `/users/${userID}/clients`,
      }),
      transformResponse: (response, meta, arg) =>
        response.clients && response.clients.length > 0 ? response.clients[0] : null,
      transformErrorResponse: customTransformErrorResponse,
      providesTags: (result, error, arg) => [{ type: 'Users', id: arg.userID }],
    }),
    addProviderToClient: builder.mutation({
      query: ({ userID, clientID }) => ({
        url: `/users/${userID}/clients/${clientID}`,
        method: 'POST',
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        { type: 'Users', id: arg.userID },
        { type: 'Clients' },
        { type: 'Client_Users' },
      ],
    }),
    removeProviderFromClient: builder.mutation({
      query: ({ userID, clientID }) => ({
        url: `/users/${userID}/clients/${clientID}`,
        method: 'DELETE',
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        { type: 'Users', id: arg.userID },
        { type: 'Clients' },
        { type: 'Client_Users' },
      ],
    }),
    assignCasesToUser: builder.mutation({
      query: ({ userID, caseIDList }) => ({
        url: `/users/${userID}/cases`,
        method: 'POST',
        body: caseIDList,
      }),
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        { type: 'Cases' },
        ...arg.caseIDList.map((caseID) => ({ type: 'Assignees', id: arg.caseID })),
        ...arg.caseIDList.map((caseID) => ({ type: 'Events', id: arg.caseID })),
        ...arg.caseIDList.map((caseID) => ({ type: 'CaseDetail', id: arg.caseID })),
      ],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetUserQuery,
  useGetUsersQuery,
  useInviteUserMutation,
  useAddUserMutation,
  useForceLogoutMutation,
  useAddRoleToUserMutation,
  useDeleteRoleFromUserMutation,
  useGetClientByUserIDQuery,
  useAddProviderToClientMutation,
  useRemoveProviderFromClientMutation,
  useAssignCasesToUserMutation,
} = mCoderUsersApi;
