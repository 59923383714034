import { useEffect } from 'react';
// api
import { useGetClientsQuery } from '../../apis/mCoderApi';
// hooks
import { useErrorNotice } from '../../../hooks';

export function useGetClients() {
  const noticeError = useErrorNotice();

  const getClients = useGetClientsQuery();
  useEffect(() => {
    if (getClients.isError) {
      noticeError(getClients.error);
    }
  }, [getClients, noticeError]);

  return getClients;
}

export function useClients() {
  const getClients = useGetClients();
  return [getClients.isSuccess ? getClients.data : [], getClients];
}

export function useClientName(clientID) {
  const [clients, getClients] = useClients();
  return [clients.find((client) => client.id === clientID)?.name, getClients];
}
