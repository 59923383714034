export const TEXTS = {
  DELETE_REASON_DIALOG__STEPPER0__LABEL: 'Add Delete Reason Tags',
  DELETE_REASON_DIALOG__STEPPER0__INFO: 'You must choose a delete reason tag to explain why to delete this case.',
  DELETE_REASON_DIALOG__STEPPER1__LABEL: 'Write Comments',
  DELETE_REASON_DIALOG__STEPPER1__INFO: 'You can optionally write a comment to explain why to delete this case.',

  DUPLICATE_REASON_DIALOG__STEPPER0__LABEL: 'Add Duplicate Reason Tags',
  DUPLICATE_REASON_DIALOG__STEPPER0__INFO:
    'You must choose a duplicate reason tag to explain why to mark this case as duplicated.',
  DUPLICATE_REASON_DIALOG__STEPPER1__LABEL: 'Write Comments',
  DUPLICATE_REASON_DIALOG__STEPPER1__INFO:
    'You can optionally write a comment to explain why to mark this case as duplicated.',
};

export const STATUS_TEXT__ALL = 'All';
export const STATUS_TEXT__COMMUNICATION = 'Communication';

export const STATUS = {
  ALL: 'all',
  CREATED: 'created',
  REQ_MODEL_CODING: 'req_model_coding',
  MODEL_CODED: 'model_coded',
  REQ_MANUAL_CODING: 'req_manual_coding',
  MANNUAL_CODED: 'manual_coded',
  HOLD: 'hold',
  COMMITTED: 'committed',
  CLOSED: 'closed',
  PROVIDER_PREREVIEW: 'req_provider_prereview',
  PROVIDER_REVIEW: 'req_provider_review',
  PROVIDER_REVIEWED: 'provider_reviewed',
  PROVIDER_HOLD: 'provider_hold',
  COMM: 'communication',
};

export const STATUS_LIST = {
  CODER: [STATUS.REQ_MANUAL_CODING, STATUS.HOLD, STATUS.COMMITTED, STATUS.CLOSED],
  COORDINATOR: [STATUS.PROVIDER_PREREVIEW, STATUS.PROVIDER_REVIEWED],
  PROVIDER: [STATUS.PROVIDER_REVIEW, STATUS.PROVIDER_HOLD],
};

export const REQ_STATUS = {
  PARSING: 'Parsing',
  IMPORTING: 'Importing',
  MODEL_CODING: 'Model Coding',
  MANUAL_CODING: 'Manual Coding',
  COMMITTED: 'Committed',
  CLOSED: 'Closed',
  FAILED: 'Parse Failed',
};

export const FEEDBACK_ACTIONS = {
  HOLD: {
    title: 'Hold',
    field: 'hold',
    color: 'warning',
    mainContent: 'If this case need an investigation and cannot be determined, please click "Hold" button.',
    sideContent: 'This case can be revisited via "Communication › Review › Hold" after you choose this option.',
  },
  NO_CHANGE: {
    title: 'No Change',
    field: 'no_change',
    color: 'primary',
    mainContent: 'If this case should be coded as it is, please click "No Change" button.',
    sideContent:
      'This case will be removed from "Communication" after you choose this option, and returned to the coder.',
  },
  ADDITIONAL_INFORMATION: {
    title: 'Additional Information',
    field: 'additional_information',
    color: 'primary',
    mainContent:
      'If this case need instructions, please write down the detailed instruction notes, and then click "Additional Information" button.',
    sideContent:
      'This case will be removed from "Communication" after you choose this option, and returned to the coder.',
  },
  ADDENDUM_COMPLETED: {
    title: 'Addendum Completed',
    field: 'addendum_completed',
    color: 'primary',
    mainContent:
      'Do *NOT* use this option, unless the addendum has actually been completed, please click "Addendum Completed" button.',
    sideContent:
      'This case will be removed from "Communication" after you choose this option, and returned to the coder.',
  },
};

// some tags are used as a flag, the name doesn't matter.
// For example, "potential bundling" tag group, we only need to show "potential bundling"
// the tag name doesn't matter. we use a pesudo name "1" to indicate the tag exists.
export const EXISTS_TAG = '1';
export const TAG_GROUPS = {
  DELETE_REASON: 'Delete Reason',
  DUPLICATE_REASON: 'Duplicate Reason',
  POTENTIAL_BUNDLING: 'Potential Bundling',
};

export const COL_ABS_NUMBER = '#';
export const COL_PCT_NUMBER = '%';
