// @mui
import { Stack, Typography, Paper } from '@mui/material';
// layouts
import LoginLayout from '../../layouts/login';

import AuthWithMS from './AuthWithMS';

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <LoginLayout title=" ">
      <Paper
        elevation={10}
        sx={{
          p: 5,
          borderRadius: 1,
          width: 400,
          height: 400
        }}
      >
        <Stack spacing={2}>
          <Typography variant="h4" align="center">
            Welcome to the Minset mCoder Portal
          </Typography>
          <AuthWithMS />
          <Typography variant="body2" align="center" sx={{ fontStyle: 'italic' }}>
            mCoder is currently released as an early-stage alpha version for the purpose of
            receiving feedback to help improve it.
          </Typography>
          <Typography variant="body2" align="center" sx={{ fontStyle: 'italic' }}>
            Please let us know what you think at{' '}
            <a href="mailto:support@minset.ai">support@minset.ai</a>
          </Typography>
        </Stack>
      </Paper>
    </LoginLayout>
  );
}
