import { mCoderApi, customTransformErrorResponse } from './base';

const __buildQS = (qs, k, v) => {
  if (v) {
    return qs && qs.length > 0 ? `&${k}=${v}` : `${k}=${v}`;
  }
  return '';
};

const mCoderMetricsApi = mCoderApi.injectEndpoints({
  tagTypes: ['Metrics'],
  endpoints: (builder) => ({
    getMetrics: builder.query({
      query: ({ metric_group, cate, sub_cate, metric, startDate, endDate }) => {
        let query = '';
        query += __buildQS(query, 'cate', cate);
        query += __buildQS(query, 'sub_cate', sub_cate);
        query += __buildQS(query, 'metric', metric);
        query += __buildQS(query, 'start_date', startDate);
        query += __buildQS(query, 'end_date', endDate);

        const path = `/metric_groups/${metric_group}`;
        return query && query.length ? `${path}?${query}` : path;
      },
      transformResponse: (response, meta, arg) => response,
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Metrics'],
    }),
    getClientInventoryMetrics: builder.query({
      query: ({ urlSearchParams }) => {
        const path = '/metrics/client/inventory';
        return urlSearchParams ? `${path}?${urlSearchParams}` : path;
      },
      transformResponse: (response, meta, arg) => response,
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Metrics'],
    }),
    getClientUnclosedCases: builder.query({
      query: ({ urlSearchParams }) => {
        const path = '/metrics/client/unclosed_cases';
        return urlSearchParams ? `${path}?${urlSearchParams}` : path;
      },
      transformResponse: (response, meta, arg) => response,
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Metrics'],
    }),
    getClientProcessingTimeMetrics: builder.query({
      query: ({ client_id = null, queue_id = null, startDate = null, endDate = null }) => {
        let query = '';
        query += __buildQS(query, 'client_id', client_id);
        query += __buildQS(query, 'queue_id', queue_id);
        query += __buildQS(query, 'start_date', startDate);
        query += __buildQS(query, 'end_date', endDate);

        const path = '/metrics/client/processing_time';
        return query && query.length ? `${path}?${query}` : path;
      },
      transformResponse: (response, meta, arg) => response,
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Metrics'],
    }),
    getClientAgingMetrics: builder.query({
      query: ({ client_id = null, queue_id = null, startDate = null, endDate = null }) => {
        let query = '';
        query += __buildQS(query, 'client_id', client_id);
        query += __buildQS(query, 'queue_id', queue_id);
        query += __buildQS(query, 'start_date', startDate);
        query += __buildQS(query, 'end_date', endDate);

        const path = '/metrics/client/aging';
        return query && query.length ? `${path}?${query}` : path;
      },
      transformResponse: (response, meta, arg) => response,
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Metrics'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetMetricsQuery,
  useGetClientInventoryMetricsQuery,
  useGetClientUnclosedCasesQuery,
  useGetClientProcessingTimeMetricsQuery,
  useGetClientAgingMetricsQuery,
} = mCoderMetricsApi;
