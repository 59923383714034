import { Helmet } from 'react-helmet-async';

import Login from './Login';

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title> Sign in to mCoder Portal</title>
      </Helmet>

      <Login />
    </>
  );
}
