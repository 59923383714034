import { useCallback } from 'react';
// api
import { useRemoveProviderFromClientMutation } from '../../apis/mCoderApi';
// hooks
import { useErrorNotice, useSuccessNotice } from '../../../hooks';

export function useRemoveProviderFromClientHandler(user, client, handleSuccess = () => {}) {
  const { id: userID, name: userName } = user;
  const { id: clientID, name: clientName } = client;
  const noticeError = useErrorNotice();
  const noticeSuccess = useSuccessNotice();
  const [removeProviderFromClient, removeProviderFromClientResult] = useRemoveProviderFromClientMutation();
  return [
    useCallback(() => {
      removeProviderFromClient({ selectedClientID: clientID, userID })
        .unwrap()
        .then(() => {
          noticeSuccess(`${userName} is removed from the client ${clientName}`);
          handleSuccess();
        })
        .catch((error) => {
          noticeError(error);
        });
    }, [removeProviderFromClient, noticeError, noticeSuccess, userID, userName, clientID, clientName, handleSuccess]),
    removeProviderFromClientResult,
  ];
}
