import { createSlice } from '@reduxjs/toolkit';
// config
import {
  ROLE_MEMBER,
  ROLE_ADMIN,
  ROLE_CODER,
  ROLE_CODER_MANAGER,
  ROLE_PROVIDER,
  ROLE_COORDINATOR,
  MCODER_API,
} from '../../config-global';

const transformMsalAcctToUser = (msalAccount) => {
  /*
        account: {
            name: "Hao Wei"
            username: "hwei@minsetaioutlook.onmicrosoft.com"
            idTokenClaims: {
                "aud": "3260aca4-0e32-40bc-95f9-0dff755e9b9d",
                "iss": "https://login.microsoftonline.com/619e7e04-0e72-4984-abb1-6e21ab9ee39a/v2.0",
                "iat": 1679366294,
                "nbf": 1679366294,
                "exp": 1679370194,
                "acct": 0,
                "aio": "",
                "auth_time": 1661590385,
                "groups": [
                    "6ae78b86-4164-4294-adc2-b2da3ae0adba",
                    "1a6f1438-f997-42db-8aa7-17ed98f05512",
                    "47779353-9855-480d-9c92-52495745e781",
                    "8c72bd56-2fab-491d-8ba3-802193b74d69",
                    "2f053b6a-17ca-4914-a74d-672f91de1d5d",
                    "5e5f8b78-4da8-406b-aeb1-ac7a637be789",
                    "59a374b3-1010-4a3e-b54a-7ca889c35073"
                ],
                "ipaddr": "",
                "name": "Hao Wei",
                "nonce": "",
                "oid": "ae407084-ba53-440e-b83b-41a044a81c08",
                "preferred_username": "hwei@minsetaioutlook.onmicrosoft.com",
                "rh": "",
                "roles": [
                    "mCoder.Member"
                ],
                "sub": "",
                "tid": "",
                "uti": "",
                "ver": "2.0"
            }
        }
    */
  if (msalAccount === undefined || msalAccount === null) {
    return {};
  }

  const { idTokenClaims } = msalAccount;
  if (idTokenClaims === undefined || idTokenClaims === null) {
    return {};
  }

  const { oid, name } = idTokenClaims;
  return {
    id: oid,
    displayName: name,
  };
};

const injectUserRoleIfAccountHasNoRole = (roles) => {
  const finalRoles = roles && roles.length > 0 ? [...roles] : [];
  // We will always add MEMBER role to user
  // It means a user will be MEMBER by default.
  if (!finalRoles.includes(ROLE_MEMBER)) {
    finalRoles.push(ROLE_MEMBER);
  }
  return finalRoles;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isInitialized: false,
    isAuthenticated: false,
    user: null,
  },
  reducers: {
    authInitialized: (draftState, action) => {
      const { user, roles, isAuthenticated } = action.payload;
      draftState.isInitialized = true;
      draftState.isAuthenticated = isAuthenticated;
      draftState.user = transformMsalAcctToUser(user);
      draftState.user.roles = injectUserRoleIfAccountHasNoRole(roles);
    },
    authImpersonate: (draftState, action) => {
      const { roles } = action.payload;
      draftState.user.roles = injectUserRoleIfAccountHasNoRole(roles);
    },
    signedIn: (draftState, action) => {
      const { user, roles } = action.payload;
      draftState.isAuthenticated = true;
      draftState.user = transformMsalAcctToUser(user);
      draftState.user.roles = injectUserRoleIfAccountHasNoRole(roles);
    },
    signedOut: (draftState, action) => {
      draftState.isInitialized = false;
      draftState.isAuthenticated = false;
      draftState.user = null;
    },
  },
});

export const { authInitialized, authImpersonate, signedIn, signedOut } = authSlice.actions;

export default authSlice.reducer;

export const selectAuth = (state) => state.auth;
export const selectUser = (state) => ({
  ...state.auth.user,
  getRoles: () => state.auth.user?.roles ?? [],
});
export const selectUserID = (state) => state.auth.user?.id;
export const selectUserName = (state) => state.auth.user?.displayName;
export const selectUserEmail = (state) => state.auth.user?.email;
export const selectUserPhotoURL = (state) => state.auth.user?.photoURL;
export const selectUserRoles = (state) => selectUser(state).getRoles() ?? [];
export const selectUserDisplayRole = (state) => {
  const roles = selectUserRoles(state);
  // eslint-disable-next-line no-restricted-syntax
  for (const [role, displayName] of Object.entries(MCODER_API.roles.displayMapping)) {
    if (roles.indexOf(role) > -1) {
      return displayName;
    }
  }
  return 'User';
};
export const selectUserIs = (role) => (state) => selectUserRoles(state).includes(role) ?? false;
export const selectUserIsCoder = selectUserIs(ROLE_CODER);
export const selectUserIsAdmin = selectUserIs(ROLE_ADMIN);
export const selectUserIsCoderManager = selectUserIs(ROLE_CODER_MANAGER);
export const selectUserIsProvider = selectUserIs(ROLE_PROVIDER);
export const selectUserIsCoordinator = selectUserIs(ROLE_COORDINATOR);
export const selectUserUnmatchedRoles = (expectedRoles) => (state) =>
  !expectedRoles.some((role) => selectUserRoles(state).includes(role));
