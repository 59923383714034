import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const DEFAULT_EXACT_MATCH = '';
const DEFAULT_SORT_BY = [{ field: 'last_modified_utc_at', direction: 'desc' }];
const DEFAULT_HIDE_COLUMNS = {
  id: false,
  dob: false,
  service_site: false,
  created_utc_at: false,
  last_modified_utc_at: false,
  status: false,
};

export const caseQuerySlice = createSlice({
  name: 'caseQuery',
  initialState: {
    exactMatch: DEFAULT_EXACT_MATCH,
    sortBy: DEFAULT_SORT_BY,
    // [{ label, field, ge, lt }, ...]
    dateFilters: [],
    // [{name, tags: [{name}, ...]}, ...]
    tagFilters: [],
    // [{id, name}, ...]
    assignedToFilters: [],
    // [{label, field, values: [str, ...]}]
    attrFilters: [],
    // [{label, field, values: [str, ...]}]
    codeFilters: [],
    hideColumns: DEFAULT_HIDE_COLUMNS,
    isDeleted: null,
    isDuplicated: null,
  },
  reducers: {
    queryReset: (draftState, action) => {
      draftState.exactMatch = DEFAULT_EXACT_MATCH;
      draftState.tagFilters = [];
      draftState.dateFilters = [];
      draftState.assignedToFilters = [];
      draftState.attrFilters = [];
      draftState.codeFilters = [];
      draftState.sortBy = DEFAULT_SORT_BY;
      draftState.hideColumns = DEFAULT_HIDE_COLUMNS;
      draftState.isDeleted = null;
      draftState.isDuplicated = null;
    },
    hideColumnsUpdated: (draftState, action) => {
      draftState.hideColumns = action.payload ?? DEFAULT_HIDE_COLUMNS;
    },
    exactMatchUpdated: (draftState, action) => {
      draftState.exactMatch = action.payload.exactMatch ?? DEFAULT_EXACT_MATCH;
    },
    sortByUpdated: (draftState, action) => {
      const { sortBy } = action.payload ?? {};
      draftState.sortBy = sortBy && sortBy.direction ? [sortBy] : DEFAULT_SORT_BY;
    },
    attrFilterAdded: (draftState, action) => {
      // {label, field, values: [str, ...]}
      const { label, field, values } = action.payload;
      const attrFilter = draftState.attrFilters.find((x) => x.field === field);
      if (!attrFilter) {
        draftState.attrFilters = [...draftState.attrFilters.filter((x) => x.field !== field), { label, field, values }];
      } else {
        const oldValues = attrFilter.values ?? [];
        const newValues = values;
        const mergedValues = [...new Set([...oldValues, ...newValues])];
        attrFilter.values = mergedValues;
      }
    },
    attrFilterRemoved: (draftState, action) => {
      // {field, value }
      const { field, value } = action.payload;
      const attrFilter = draftState.attrFilters.find((x) => x.field === field);
      if (attrFilter) {
        const otherAttrFilters = draftState.attrFilters.filter((x) => x.field !== field);
        const values = attrFilter.values.filter((x) => x !== value);
        if (values && values.length > 0) {
          draftState.attrFilters = [...otherAttrFilters, { ...attrFilter, values }];
        } else {
          draftState.attrFilters = otherAttrFilters;
        }
      }
    },
    codeFilterAdded: (draftState, action) => {
      const { label, field, values } = action.payload;
      const codeFilter = draftState.codeFilters.find((x) => x.field === field);
      if (!codeFilter) {
        draftState.codeFilters = [...draftState.codeFilters.filter((x) => x.field !== field), { label, field, values }];
      } else {
        const oldValues = codeFilter.values ?? [];
        const newValues = values;
        const mergedValues = [...new Set([...oldValues, ...newValues])];
        codeFilter.values = mergedValues;
      }
    },
    codeFilterRemoved: (draftState, action) => {
      // {field, value }
      const { field, value } = action.payload;
      const codeFilter = draftState.codeFilters.find((x) => x.field === field);
      if (codeFilter) {
        const otherCodingFilters = draftState.codeFilters.filter((x) => x.field !== field);
        const values = codeFilter.values.filter((x) => x !== value);
        if (values && values.length > 0) {
          draftState.codeFilters = [...otherCodingFilters, { ...codeFilter, values }];
        } else {
          draftState.codeFilters = otherCodingFilters;
        }
      }
    },
    dateFilterAdded: (draftState, action) => {
      // { label, field, ge, lt }
      const { label, field, ge, lt } = action.payload;
      draftState.dateFilters = [
        ...draftState.dateFilters.filter((x) => x.field !== field),
        {
          label,
          field,
          // Set time to 00:00 for ge
          ge: ge ? dayjs(ge).startOf('d').format('M-D-YYYY') : null,
          // - Set time to 23:55 for lt
          lt: lt ? dayjs(lt).endOf('d').format('M-D-YYYY') : null,
        },
      ];
    },
    dateFilterRemoved: (draftState, action) => {
      // { label, field, ge, lt }
      const { field } = action.payload;
      draftState.dateFilters = [...draftState.dateFilters.filter((x) => x.field !== field)];
    },
    tagFilterAdded: (draftState, action) => {
      // { tagFilterName, tags: [{name}, ...]}
      const { tagFilterName, tags } = action.payload;
      const tagFilter = draftState.tagFilters.find((x) => x.name === tagFilterName);
      if (!tagFilter) {
        draftState.tagFilters = [
          ...draftState.tagFilters.filter((x) => x.name !== tagFilterName),
          { name: tagFilterName, tags },
        ];
      } else {
        const oldTagNames = (tagFilter.tags ?? []).map((tag) => tag.name);
        const newTagNames = tags.map((tag) => tag.name);
        const mergedTagNames = [...new Set([...oldTagNames, ...newTagNames])];
        tagFilter.tags = mergedTagNames.map((tagName) => ({ name: tagName }));
      }
    },
    tagFilterRemoved: (draftState, action) => {
      const { tagFilterName, tagName } = action.payload;
      const tagFilter = draftState.tagFilters.find((x) => x.name === tagFilterName);
      if (tagFilter) {
        const otherTagFilters = draftState.tagFilters.filter((x) => x.name !== tagFilterName);
        const tags = tagFilter.tags.filter((tag) => tag.name !== tagName);
        if (tags && tags.length > 0) {
          draftState.tagFilters = [...otherTagFilters, { name: tagFilterName, tags }];
        } else {
          draftState.tagFilters = otherTagFilters;
        }
      }
    },
    assginedToFilterAdded: (draftState, action) => {
      // [{id: ..., name: ...}, ...]
      const { assignedToFilters } = action.payload;
      const dedupAssignedToFilters = assignedToFilters.filter(
        (x) => !draftState.assignedToFilters.find((y) => y.id === x.id)
      );
      draftState.assignedToFilters = [...draftState.assignedToFilters, ...dedupAssignedToFilters];
    },
    assginedToFilterRemoved: (draftState, action) => {
      // {id: ..., name: ...}
      const { id } = action.payload;
      draftState.assignedToFilters = [...draftState.assignedToFilters.filter((x) => x.id !== id)];
    },
    isDeletedSet: (draftState, action) => {
      const { isDeleted } = action.payload ?? {};
      draftState.isDeleted = isDeleted;
    },
    isDuplicatedSet: (draftState, action) => {
      const { isDuplicated } = action.payload ?? {};
      draftState.isDuplicated = isDuplicated;
    },
  },
});

export const {
  queryReset,
  hideColumnsUpdated,
  exactMatchUpdated,
  dateFilterAdded,
  dateFilterRemoved,
  tagFilterAdded,
  tagFilterRemoved,
  assginedToFilterAdded,
  assginedToFilterRemoved,
  attrFilterAdded,
  attrFilterRemoved,
  codeFilterAdded,
  codeFilterRemoved,
  sortByUpdated,
  isDeletedSet,
  isDuplicatedSet,
} = caseQuerySlice.actions;

export default caseQuerySlice.reducer;

export const DATE_QUERY_FORMAT = 'M-D-YYYY';

export const buildQueryObject = ({
  clientIDList,
  queueIDList,
  dateField,
  ge = null,
  lt = null,
  isDeleted = null,
  isDuplicated = null,
}) => {
  const q = {};

  if (isDeleted !== null && isDeleted !== undefined) {
    q.is_deleted_filter = isDeleted;
  }

  if (isDuplicated !== null && isDuplicated !== undefined) {
    q.is_duplicated_filter = isDuplicated;
  }

  if (clientIDList && clientIDList.length > 0) {
    q.client_id_filters = clientIDList;
  }

  if (queueIDList && queueIDList.length > 0) {
    q.queue_id_filters = queueIDList;
  }

  q.date_filters = [
    {
      field: dateField,
      // Set time to 00:00 for ge
      ge: ge ? dayjs(ge).startOf('d').format(DATE_QUERY_FORMAT) : null,
      // - Set time to 00:00 for lt
      // lt must be > ge
      lt: lt ? dayjs(lt).startOf('d').format(DATE_QUERY_FORMAT) : null,
    },
  ];

  return q;
};

export const selectJsonableQueryObject = (state) => {
  /*
{
  "exact_match": "tjZGH",
  "tag_filters": [
    {
      "id": -1,
      "name": "zoo",
      "tags": [
        {
          "id": -1,
          "name": "lion",
          "tag_group_id": -1
        },
        {
          "id": -1,
          "name": "tiger",
          "tag_group_id": -1
        }
      ]
    },
    {
      "id": -1,
      "name": "fruit",
      "tags": [
        {
          "id": -1,
          "name": "apple",
          "tag_group_id": -1
        },
        {
          "id": -1,
          "name": "banana",
          "tag_group_id": -1
        }
      ]
    }
  ],
  "status_filters": [
    "req_manual_coding"
  ],
  "assigned_to_filters": [
    "4534cdbf-c425-43be-a0c0-bfe5bb586596",
    "19d967f2-48fc-4b99-af38-14c02b0ab8b0"
  ],
  "date_filters": [
    {
      "field": "created_utc_at",
      "ge": "02-09-2023",
      "lt": "05-06-2023"
    },
    {
      "field": "created_utc_at",
      "ge": "05-10-2023",
      "lt": "05-13-2023"
    }
  ],
  "sort_opts": [
    {
      "field": "assgined_utc_at",
      "direction": "asc"
    }
  ],
  "pagination_opts": {
    "skip": 0,
    "limit": 10
  }
}
  */
  const q = {};

  if (state.caseQuery.isDeleted !== null && state.caseQuery.isDeleted !== undefined) {
    q.is_deleted_filter = state.caseQuery.isDeleted;
  }

  if (state.caseQuery.isDuplicated !== null && state.caseQuery.isDuplicated !== undefined) {
    q.is_duplicated_filter = state.caseQuery.isDuplicated;
  }

  if (state.caseQuery.sortBy && state.caseQuery.sortBy.length > 0) {
    q.sort_opts = state.caseQuery.sortBy;
  }

  if (state.caseQuery.exactMatch) {
    q.exact_match = state.caseQuery.exactMatch;
  }

  if (state.caseQuery.assignedToFilters && state.caseQuery.assignedToFilters.length > 0) {
    q.assigned_to_filters = state.caseQuery.assignedToFilters.map((u) => u.id);
  }

  // dayjs default timezone is UTC, pleaes read: https://day.js.org/docs/en/plugin/timezone
  // const dayjsLocal = dayjs(timestamp); //assumes UTC
  // //dayjsLocal.toISOString() -> 2014-06-01T12:00:00.000Z
  if (state.caseQuery.dateFilters && state.caseQuery.dateFilters.length > 0) {
    q.date_filters = state.caseQuery.dateFilters;
  }

  if (state.caseQuery.tagFilters && state.caseQuery.tagFilters.length > 0) {
    q.tag_filters = state.caseQuery.tagFilters;
  }

  if (state.caseQuery.attrFilters && state.caseQuery.attrFilters.length > 0) {
    q.attr_filters = state.caseQuery.attrFilters;
  }

  if (state.caseQuery.codeFilters && state.caseQuery.codeFilters.length > 0) {
    q.code_filters = state.caseQuery.codeFilters.map(({ label, field, values }) => ({ field, values }));
  }

  return q;
};

export const selectExactMatch = (state) => state.caseQuery.exactMatch;
export const selectHideColumns = (state) => state.caseQuery.hideColumns;
export const selectSortBy = (state) => state.caseQuery.sortBy[0];
export const selectTagFilters = (state) => state.caseQuery.tagFilters;
export const selectAttrFilters = (state) => state.caseQuery.attrFilters;
export const selectCodeFilters = (state) => state.caseQuery.codeFilters;
export const selectDateFilters = (state) => state.caseQuery.dateFilters;
export const selectAssignedToFilters = (state) => state.caseQuery.assignedToFilters;
export const selectIsDeleted = (state) => state.caseQuery.isDeleted;
export const selectIsDuplicated = (state) => state.caseQuery.isDuplicated;
