import { useEffect } from 'react';
// api
import { useGetCommentsQuery } from '../../apis/mCoderApi';
// hooks
import { useErrorNotice } from '../../../hooks';

function useGetComments(caseID) {
  const noticeError = useErrorNotice();
  const getComments = useGetCommentsQuery({ caseID });
  useEffect(() => {
    if (getComments.isError) {
      noticeError(getComments.error);
    }
  }, [noticeError, getComments]);
  return getComments;
}

export function useComments(caseID) {
  const getComments = useGetComments(caseID);
  return [getComments?.data ?? [], getComments];
}
