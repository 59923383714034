import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Typography, Tooltip, Link, Avatar, AvatarGroup } from '@mui/material';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
import { ForceLogout } from '../../../components/ForceLogout';
import { ConfirmationDialog } from '../../../components/dialogs';
// selector
import {
  selectUserPhotoURL,
  selectUserName,
  selectUserIsCoder,
  selectUserIsAdmin,
  selectUserIsProvider,
  selectUserIsCoderManager,
  selectUserIsCoordinator,
} from '../../../features/auth/authSlice';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// route
import { PATHS } from '../../../routes/paths';
// hooks
import { useDialog } from '../../../hooks';
import { useEnableAutoUnassign } from '../../../features/config/hooks';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

RoleGroup.propTypes = {
  sx: PropTypes.object,
};

function RoleGroup({ sx }) {
  const roles = [];

  const isAdmin = useSelector(selectUserIsAdmin);
  if (isAdmin) {
    roles.push({ label: 'A', tooltip: 'Administrator', bgcolor: (t) => t.palette.error.light });
  }

  const isCoderManager = useSelector(selectUserIsCoderManager);
  if (isCoderManager) {
    roles.push({ label: 'CM', tooltip: 'Coder Manager', bgcolor: (t) => t.palette.error.lighter });
  }

  const isCoder = useSelector(selectUserIsCoder);
  if (isCoder) {
    roles.push({ label: 'C', tooltip: 'Coder', bgcolor: (t) => t.palette.primary.lighter });
  }

  const isCoordinator = useSelector(selectUserIsCoordinator);
  if (isCoordinator) {
    roles.push({ label: 'Co', tooltip: 'Coordinator', bgcolor: (t) => t.palette.success.light });
  }

  const isProvider = useSelector(selectUserIsProvider);
  if (isProvider) {
    roles.push({ label: 'P', tooltip: 'Provider', bgcolor: (t) => t.palette.success.lighter });
  }

  return (
    <AvatarGroup max={6} variant="circular" sx={sx}>
      {roles.map(({ label, tooltip, bgcolor, color }) => (
        <Tooltip key={label} title={tooltip}>
          <Avatar alt={tooltip} sx={{ bgcolor, color, width: 20, height: 20 }}>
            {label}
          </Avatar>
        </Tooltip>
      ))}
    </AvatarGroup>
  );
}

// ----------------------------------------------------------------------
export default function NavAccount() {
  const navigate = useNavigate();
  const { logout } = useAuthContext();
  const photoURL = useSelector(selectUserPhotoURL);
  const userName = useSelector(selectUserName);

  const handleLogout = () => {
    try {
      navigate(PATHS.login, { replace: true });
      logout();
    } catch (error) {
      // For logout, we won't popup the error, just output to the console.
      console.log(error);
    }
  };

  const [, enableAutoUnassign] = useEnableAutoUnassign();

  const [openDialog, dialog] = useDialog({
    createDialog: ({ opened, handleClose }) => (
      <ConfirmationDialog
        description='Click "Logout" button for confirmation'
        warningDescription={
          enableAutoUnassign ? 'After logout, all the cases assigned to you will be unassigned.' : null
        }
        confirmButtonText="Logout"
        color="primary"
        open={opened}
        onClose={handleClose}
        onConfirm={handleLogout}
      />
    ),
  });

  return (
    <StyledRoot>
      {dialog}
      <ForceLogout onLogout={handleLogout} />

      <CustomAvatar src={photoURL} alt={userName} name={userName} />

      <Box sx={{ ml: 2, minWidth: 0 }}>
        <Link component={RouterLink} to={PATHS.profile} color="inherit">
          <Tooltip title="Click to read profile">
            <Typography variant="subtitle2" noWrap>
              {userName}
            </Typography>
          </Tooltip>
        </Link>

        <RoleGroup />
      </Box>
      <Tooltip title="Click for logout">
        <LogoutTwoToneIcon sx={{ ml: 'auto', cursor: 'pointer' }} onClick={openDialog} />
      </Tooltip>
    </StyledRoot>
  );
}
