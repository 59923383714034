import { useCallback } from 'react';
// api
import { useAddProviderToClientMutation } from '../../apis/mCoderApi';
// hooks
import { useErrorNotice, useSuccessNotice, useWarningNotice } from '../../../hooks';

export function useAddProviderToClientHandler(user, handleSuccess = () => {}) {
  const { id: userID, name: userName } = user;
  const noticeError = useErrorNotice();
  const noticeSuccess = useSuccessNotice();
  const noticeWarning = useWarningNotice();
  const [addProviderToClient, addProviderToClientResult] = useAddProviderToClientMutation();
  return [
    useCallback(
      (clientID) => {
        if (clientID) {
          addProviderToClient({ clientID, userID })
            .unwrap()
            .then(() => {
              noticeSuccess(`${userName} is added to the client.`);
              handleSuccess();
            })
            .catch((error) => {
              noticeError(error);
            });
        } else {
          noticeWarning('Please choose a client for user.');
          handleSuccess();
        }
      },
      [addProviderToClient, noticeError, noticeSuccess, noticeWarning, userID, userName, handleSuccess]
    ),
    addProviderToClientResult,
  ];
}
