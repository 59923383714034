import { useEffect } from 'react';
// api
import { useGetClientByUserIDQuery } from '../../apis/mCoderApi';
// hooks
import { useErrorNotice } from '../../../hooks';

function useGetClientByUserID(query, opts) {
  const getClientByUserID = useGetClientByUserIDQuery(query, opts);
  const noticeError = useErrorNotice();
  useEffect(() => {
    if (getClientByUserID.isError) {
      noticeError(getClientByUserID.error);
    }
  }, [noticeError, getClientByUserID]);
  return getClientByUserID;
}

export function useClientByUserID(userID) {
  const getClientByUserID = useGetClientByUserID({ userID });
  return [getClientByUserID.data ?? {}, getClientByUserID];
}
