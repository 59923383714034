import { useEffect } from 'react';
// api
import { useGetClientQuery } from '../../apis/mCoderApi';
// hooks
import { useErrorNotice } from '../../../hooks';

export function useGetClient(clientID) {
  const noticeError = useErrorNotice();

  const getClient = useGetClientQuery(clientID);
  useEffect(() => {
    if (getClient.isError) {
      noticeError(getClient.error);
    }
  }, [getClient, noticeError]);

  return getClient;
}

export function useClient(clientID) {
  const getClient = useGetClient(clientID);
  return [getClient.data ?? {}, getClient];
}
